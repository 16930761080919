import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Header from './components/Header';
import Hero from './components/Hero';
import Brands from './components/brands';
import About from './components/about';
import Footer from './components/Footer';
import ScrollToTopButton from './components/ScrollToTopButton';

export default function App() {
  return (
    <BrowserRouter basename='/'>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<Hero />} />
          <Route path="/brands" element={<Brands />} />
          <Route path="/about" element={<About />} />
        </Routes>
        <Footer />
      </div>
      <Routes>
        <Route path="*" element={<ScrollToTopButton />} />
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
