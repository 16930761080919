import React from 'react';
import { FaStar, FaThumbsUp, FaUsers, FaBullhorn } from 'react-icons/fa';

import jrpsofttech from '../img/6.png';

const About = () => {
  return (
    <section className="features" id="features">
      
    <section className="hero" id="home">
      <img src=".svg" id="header-shape" alt="header jrp soft tech" />
      <div className="hero-img">
        <img  src={jrpsofttech} alt="jrp soft tech" />
      </div>
      <div className="hero-content">
        
        <h1 style={{ color: 'white', fontFamily: 'Gill Sans, sans-serif' }}>
            About Us 
        </h1>
        
        <p style={{ color: 'white', fontSize: '2rem' }}>
        <strong>JRP Group, also known as Jay Rajpurohit Group, is a dynamic organization that originated from Jodhpur, Rajasthan, India. Founded by Jay Rajpurohit in 2021, the group operates with a diversified portfolio through its subsidiary companies, with JRP Soft Tech being one of them.
      </strong>
        </p>
        {/*
        <div className="btn-container">
          <a href="contact.html">
            <button className="primary-btn btn">Our Brands</button>
          </a>
          <a href="#features">
            <button className="primary-btn btn">Explore More</button>
          </a>
        </div>
        */}
      </div>
      
    </section>
    
    <section className="hero" id="home">
      <img src=".svg" id="header-shape" alt="header jrp soft tech" />
      <div className="hero-img">
        <img  src={jrpsofttech} alt="jrp soft tech" />
      </div>
      <div className="hero-content">
        
        <h1 style={{ color: 'white', fontFamily: 'Gill Sans, sans-serif' }}>
        Our vision 
        </h1>
        
        <p style={{ color: 'white', fontSize: '2rem' }}>
        <strong>To be a global leader in innovative technology solutions, transforming the digital landscape by empowering businesses, individuals, and communities with accessible and affordable cutting-edge technologies.
      </strong>
        </p>
        
        {/*
        <div className="btn-container">
          <a href="contact.html">
            <button className="primary-btn btn">Our Brands</button>
          </a>
          <a href="#features">
            <button className="primary-btn btn">Explore More</button>
          </a>
        </div>
        */}
      </div>
      
    </section>


    <section className="hero" id="home">
      <img src=".svg" id="header-shape" alt="header jrp soft tech" />
      <div className="hero-img">
        <img  src={jrpsofttech} alt="jrp soft tech" />
      </div>
      <div className="hero-content">
        
        <h1 style={{ color: 'white', fontFamily: 'Gill Sans, sans-serif' }}>
        Our Mission 
        </h1>
        
        <p style={{ color: 'white', fontSize: '2rem' }}>
        <strong>JRP Group is committed to making groundbreaking technologies accessible to all, from large enterprises to startups and individuals. Our mission is to provide comprehensive and affordable solutions, fostering sustainable development, and contributing to the growth and success of our clients worldwide. We strive to create a positive impact by embracing emerging technologies and driving innovation through our diverse portfolio of services and sub-brands.
      </strong>
        </p>
        
        {/*
        <div className="btn-container">
          <a href="contact.html">
            <button className="primary-btn btn">Our Brands</button>
          </a>
          <a href="#features">
            <button className="primary-btn btn">Explore More</button>
          </a>
        </div>
        */}
      </div>
      
    </section>


    </section>
  );
};

export default About;