import React from 'react';
import jrpsofttech from '../img/6.png';


const Hero = () => {
  return (
    <section className="hero" id="home">
      <img src=".svg" id="header-shape" alt="header jrp soft tech" />
      <div className="hero-content">
        <h1 style={{ color: 'white', fontFamily: 'Gill Sans, sans-serif' }}>
        Welcome to JRP Group – A Hub of Innovation and Technology Excellence!
        </h1>
        <p style={{ color: 'white', fontSize: '2rem' }}>
        JRP Group, also known as Jay Rajpurohit Group, is a comprehensive ecosystem founded by Jay Rajpurohit in 2021, originating from Jodhpur, Rajasthan, India. This dynamic group operates as a mission-driven organization, offering a diverse range of services across various technological domains.
        </p>
        <div className="btn-container">
          <a href="brands">
            <button className="primary-btn btn">Our Brands</button>
          </a>
          <a href="About">
            <button className="primary-btn btn">Explore More</button>
          </a>
        </div>
      </div>
      <div className="hero-img">
        <img  src={jrpsofttech} alt="jrp soft tech" />
      </div>
    </section>
  );
};

export default Hero;



