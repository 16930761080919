import React from 'react';

import jrpseoboost from '../img/b5.png';
import appsolutionsbyjrp from '../img/app.png';
import jrpwebtech from '../img/web.png';
import jrpdesignstudio from '../img/jrpds.png';
import jrphosting from '../img/jrphosting.png';
import jrpdigitalreach from '../img/b1.png';
import './brands.css';
import jrpsofttech from '../img/13.png';


const Brands = () => {
  return (
    <section className="features" id="features">
      {/*1 start*/}
    <section className="hero" id="home">
      <img src=".svg" id="header-shape" alt="header jrp soft tech" />
      <div className="hero-img">
        <img  src={jrpsofttech} alt="jrp soft tech" />
      </div>
      <div className="hero-content">
        
        <h1 style={{ color: 'white', fontFamily: 'Gill Sans, sans-serif' }}>
            JRP Soft Tech 
        </h1>
        
        <p style={{ color: 'white', fontSize: '2rem' }}>
        <strong>
        JRP Soft Tech, founded by Jay Rajpurohit as part of the JRP Group, is not just a company but a mission. Established in 2021 in Jodhpur, Rajasthan, India, it stands as a inspiration for global technological accessibility. The mission revolves around providing innovative tech solutions at affordable costs to a diverse clients, ranging from large multinational corporations to small businesses, MSMEs, and individuals.
      </strong>
        </p>
        <div className="btn-container">
          <a href="https://jrpsofttech.com/">
            <button className="primary-btn btn"> JRP Soft Tech  </button>
          </a>
        </div>
      </div>
      
    </section>
    {/*2 start*/}
    <section className="hero" id="home">
      <img src=".svg" id="header-shape" alt="header jrp soft tech" />
      <div className="hero-img">
        <img  src={jrpwebtech} alt="jrp soft tech" />
      </div>
      <div className="hero-content">
        
        <h1 style={{ color: 'white', fontFamily: 'Gill Sans, sans-serif' }}>
        JRP WebTech 
        </h1>
        
        <p style={{ color: 'white', fontSize: '2rem' }}>
        <strong>JRP WebTech is a sub-brand under JRP Soft Tech, specializing in providing a range of web development services. As part of the JRP Group's comprehensive approach to technology, JRP WebTech focuses on creating and enhancing online presence through various web solutions. 
      </strong>
        </p>
        <div className="btn-container">
          <a href="https://jrpwebtech.jrpsofttech.com/">
            <button className="primary-btn btn">JRP WebTech</button>
          </a>
        </div>
      
      </div>
      
    </section>

    {/*3 start*/}
    <section className="hero" id="home">
      <img src=".svg" id="header-shape" alt="header jrp soft tech" />
      <div className="hero-img">
        <img  src={appsolutionsbyjrp} alt="jrp soft tech" />
      </div>
      <div className="hero-content">
        
        <h1 style={{ color: 'white', fontFamily: 'Gill Sans, sans-serif' }}>
        App solutions by JRP  
        </h1>
        
        <p style={{ color: 'white', fontSize: '2rem' }}>
        <strong>App Solutions by JRP is a sub-brand within JRP Soft Tech, specializing in delivering comprehensive app development services. Recognizing the increasing reliance on mobile applications, App Solutions focuses on creating innovative and user-friendly apps for various platforms.
      </strong>
        </p>
        
        <div className="btn-container">
          <a href="https://appsolutions.jrpsofttech.com/">
            <button className="primary-btn btn">App Solutions by JRP</button>
          </a>
        </div>
      </div>
      
    </section>



    {/*4 start*/}
    <section className="hero" id="home">
      <img src=".svg" id="header-shape" alt="header jrp soft tech" />
      <div className="hero-img">
        <img  src={jrphosting} alt="jrp soft tech" />
      </div>
      <div className="hero-content">
        
        <h1 style={{ color: 'white', fontFamily: 'Gill Sans, sans-serif' }}>
        JRP Hosting 
        </h1>
        
        <p style={{ color: 'white', fontSize: '2rem' }}>
        <strong>JRP Hosting is a sub-brand within JRP Soft Tech, specializing in providing hosting solutions for businesses and individuals. Hosting services are crucial for ensuring a reliable and accessible online presence, and JRP Hosting caters to a diverse clientele with various hosting needs.
      </strong>
        </p>
        
        <div className="btn-container">
          <a href="brands">
            <button className="primary-btn btn">JRP Hosting</button>
          </a>
        </div>
      </div>
      
    </section>

    
    {/*5 start*/}
    <section className="hero" id="home">
      <img src=".svg" id="header-shape" alt="header jrp soft tech" />
      <div className="hero-img">
        <img  src={jrpdigitalreach} alt="jrp soft tech" />
      </div>
      <div className="hero-content">
        
        <h1 style={{ color: 'white', fontFamily: 'Gill Sans, sans-serif' }}>
        JRP Digital Reach  
        </h1>
        
        <p style={{ color: 'white', fontSize: '2rem' }}>
        <strong>JRP Digital Reach is a sub-brand under JRP Soft Tech, specializing in digital marketing services. In an era where an online presence is critical, JRP Digital Reach focuses on enhancing the visibility and reach of businesses through strategic and effective digital marketing strategies.
      </strong>
        </p>
        
        <div className="btn-container">
          <a href="brands">
            <button className="primary-btn btn">JRP Digital Reach</button>
          </a>
        </div>
      </div>
      
    </section>


    
    {/*6 start*/}
    <section className="hero" id="home">
      <img src=".svg" id="header-shape" alt="header jrp soft tech" />
      <div className="hero-img">
        <img  src={jrpdesignstudio} alt="jrp soft tech" />
      </div>
      <div className="hero-content">
        
        <h1 style={{ color: 'white', fontFamily: 'Gill Sans, sans-serif' }}>
        JRP Design Studio  
        </h1>
        
        <p style={{ color: 'white', fontSize: '2rem' }}>
        <strong>JRP Design Studio is a sub-brand operating under JRP Soft Tech, specializing in graphic design and UI/UX development. This creative arm of the company focuses on delivering visually appealing and user-centric designs for websites, applications, and branding materials.
      </strong>
        </p>
        
        <div className="btn-container">
          <a href="brands">
            <button className="primary-btn btn">JRP Design Studio</button>
          </a>
        </div>
      </div>
      
    </section>


    
    {/*7 start*/}
    <section className="hero" id="home">
      <img src=".svg" id="header-shape" alt="header jrp soft tech" />
      <div className="hero-img">
        <img  src={jrpseoboost} alt="jrp soft tech" />
      </div>
      <div className="hero-content">
        
        <h1 style={{ color: 'white', fontFamily: 'Gill Sans, sans-serif' }}>
          JRP SEO Boost  
        </h1>
        
        <p style={{ color: 'white', fontSize: '2rem' }}>
        <strong>JRP SEOBOOST is a sub-brand operating under JRP Soft Tech, specializing in search engine optimization (SEO) services. With a focus on enhancing online visibility and search engine rankings, JRP SEOBOOST help businesses and individuals in optimizing their digital content for improved search engine performance.
      </strong>
        </p>
        
        <div className="btn-container">
          <a href="brands">
            <button className="primary-btn btn">JRP SEO Boost</button>
          </a>
          
        </div>
      </div>
      
    </section>


    </section>
  );
};

export default Brands;
